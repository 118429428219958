.App {
  text-align: center;
}

/* navbar */
/* .nav-link {
  position: relative;
  text-decoration: none;
}

.nav-link span {
  position: relative;
}

.nav-link::before,
.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: blue; 
  transition: width 0.3s ease;
}

.nav-link::before {
  transform: translateX(-50%);
}

.nav-link::after {
  transform: translateX(50%);
}

.nav-link:hover::before {
  width: calc(50% - 0.5em);
}

.nav-link:hover::after {
  width: calc(50% - 0.5em);
} */
.nav-link {
  color: #18272f;
  position: relative;
  text-decoration: none;
}

.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: #18272f;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

/* text slider */
.text-textslider {
  position: relative;
  overflow: hidden;
  height: 30px; /* Adjust the height as needed */
  background-color: #03aed2;
  font-size: 20px;
}

.textslide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.textslide.active {
  opacity: 1;
}
/* text slider */

/* collections */
.collections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 20px 50px 20px 50px;
  padding: 20px;
  gap: 20px;
}

.image-container {
  position: relative;
  width: 225px;
  height: 225px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #d7e4c0;
  transition: transform 0.3s ease; /* Add transition for smooth scaling */
  filter: brightness(90%);
}

.image:hover {
  transform: scale(1.3); /* Zoom in by 10% on hover */
  filter: brightness(50%);
}

.text {
  position: absolute;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.text:hover {
  text-decoration: underline;
}
.image-container:hover .text {
  text-decoration: underline;
}

.section {
  background: rgb(172, 211, 230);
  background: linear-gradient(
    0deg,
    rgba(172, 211, 230, 1) 0%,
    rgba(186, 219, 236, 1) 96%
  );
  background-image: url(./assests/bgp01.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  height: 100%;
  /*position: absolute;*/
  min-height: 100vh;
  object-fit: cover;
  width: 100%;
}

/* dashboard navbar */
.profile-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
/* dashboard navbar */

.product-form {
  margin-bottom: 20px;
}
Form.Label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

/* Ensure the main content starts below the fixed navbar */
.mt-5 {
  margin-top: 5rem !important; /* Adjust as needed for your navbar height */
}

.pt-5 {
  padding-top: 3rem !important; /* Adjust as needed for your navbar height */
}

.table-responsive {
  max-height: 900px;
  overflow: scroll;
  scrollbar-width: thin;
}

.product-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.no-products {
  color: #2e6ca4;
  text-align: center;
}
.cell-padding {
  /* max-width: 20px; */
  width: fit-content;
  padding: 7px !important;
}

/* .product-table tr:hover {
  background-color: #f1f1f1;
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.edit-button,
.delete-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-button {
  background-color: #4caf50;
  color: white;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.no-products {
  color: #2e6ca4;
  text-align: center;
} */

.alert-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* transform: translateY(-15px); */
  /* padding-left: 40px; */
}
.alert-icon {
  color: red;
  justify-content: center;
  align-items: center;
  /* margin: 0;
  padding: 0; */
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-icon {
  animation: rotateAnimation 2s linear infinite; /* Adjust the duration and timing function as needed */
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

/* Base styles for the login container */
.login {
  width: 100%;
  /* height: 100vh; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Styles for the .left and .right sections */
.login > .left {
  width: 100%; /* Full width on smaller screens */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.login > .right {
  width: 100%; /* Full width on smaller screens */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#rc-anchor-container {
  display: none !important;
}

/* edit-profile */
.alert-container1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateY(-10px);
  padding-left: 40px;
}
.alert-icon1 {
  color: red;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.alert-msg1 {
  margin: 0 !important;
  padding-left: 5px;
  font-size: 15px;
}

/* footer-link */
.footer-link {
  color: grey;
}
.footer-link:hover {
  text-decoration: underline;
}

/* social-media */
.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.logo {
  height: 80px;
  margin-bottom: 10px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.link-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 10px;
}

.link-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  background-color: #f9f9f9;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  width: 120px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.link-box:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.link-image {
  height: 50px;
  margin-bottom: 10px;
}

.text-1 {
  font-size: 1.1rem;
  color: #555;
}

/* FAQ */
.faq-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.faq-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.faq-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-item:hover {
  background-color: #f9f9f9;
}

.faq-question-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.faq-toggle-icon {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.faq-answer {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
  padding-left: 20px;
  text-align: left;
}

/* pre-loader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

/* no-page */
.no-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url("./assests/bgp02.jpg") no-repeat center center;
  background-size: cover;
}

.no-page h1 {
  font-size: 10rem;
  font-weight: bold;
  color: #343a40;
}

.no-page h2 {
  font-size: 3rem;
  color: #343a40;
}

.no-page p {
  font-size: 1.25rem;
  color: #6c757d;
}

.no-page .btn-primary {
  font-size: 1.25rem;
  padding: 0.75rem 1.5rem;
}

/* order-summary */
.order-container {
  padding: 0.5rem;
}

.order-summary {
  margin-top: 8px;
}

.order-item {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 0.3rem;
  /* margin-bottom: 10px; */
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}
.order-item.border-green {
  border: 1px solid #78ABA8;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -30px 36px -28px inset;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.order-details {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly;
  margin: 5px 0; */
}

.order-address {
  margin-top: 10px;
}

.order-items {
  margin-top: 10px;
}

.order-product {
  /* display: flex; */
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.product-info {
  display: flex;
}

.product-image {
  width: 50px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
}

.order-product {
  padding: 0;
  font-size: small;
}

.order-product p, .order-product h6 {
  margin: 0;
}


/* .product-details p {
  margin: 5px 0;
} */

/* policy */
.policy-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 0% 5%;
}

.policy-text {
  text-align: left;
}
.policy-text > h3 {
  font-size: 15px;
}

/* General Timeline Styles */
.timeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 0; */
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: #d3d3d3;
  z-index: 0;
  transform: translateY(-50%);
}

/* Timeline Event Styles */
.timeline-event {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0; /* Ensure no margin so they align in a straight line */
}

.timeline-event.completed .timeline-content {
  color: green;
}

.timeline-event.completed .timeline-marker {
  background-color: green;
}

.timeline-event:not(.completed) .timeline-content {
  color: #aaa; /* Lighter color for uncompleted events */
}

.timeline-event:not(.completed) .timeline-marker {
  background-color: #d3d3d3;
  border: 2px solid #fff;
}

/* Timeline Marker */
.timeline-marker {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #d3d3d3;
  margin-top: 1.2rem;
  margin-bottom: 10px;
  border: 2px solid #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-marker.completed {
  background-color: green;
  color: #fff;
}

.timeline-marker.uncompleted {
  background-color: #d3d3d3;
  color: #aaa;
}

/* Timeline Content */
.timeline-content {
  text-align: center;
  /* margin-bottom: 5px; */
}

.timeline .name {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
.text-muted {
 color: #17191b;
}
.text-info {
  color: #2e6ca4;
}

.date-time {
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline .date {
  font-size: 0.7rem;
  color: #aaa;
  margin: 0;
}

/* .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  border: 2px solid red;
  border-radius: 10px;
} */

/* Responsive Styles */
@media (max-width: 599px) {
  .timeline {
    flex-direction: row;
  }

  /* .timeline::before {
    display: none;
  } */

  /* .timeline-event {
    align-items: flex-start;
    width: 100%;
  } */

  .timeline-marker {
    margin: 0 auto 10px;
  }

  .timeline-content {
    text-align: left;
  }
}



/* cart */
.text-right h6, .text-right h4 {
  margin-bottom: 0;
}

.text-right .d-flex {
  align-items: center;
}

.text-right .mr-2 {
  margin-right: 0.5rem !important;
}






/* skeleton-loader */
.image-loaded {
  opacity: 1;
}

.skeleton-image {
  position: relative;
  width: 100%; /* Adjust dimensions as per your design */
  height: auto; /* Maintain aspect ratio */
}

.skeleton-image-placeholder {
  background-color: #f0f0f0; /* Placeholder color */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.skeleton-text {
  margin-top: 8px; /* Adjust spacing as needed */
  width: 80%; /* Adjust width as needed */
  height: 16px; /* Adjust height as needed */
  background-color: #f0f0f0; /* Placeholder color */
}
