@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Cinzel:wght@400..900&family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Edu+TAS+Beginner:wght@400..700&family=Gruppo&family=Inconsolata:wght@200..900&family=Indie+Flower&family=Jost:ital,wght@0,100..900;1,100..900&family=Macondo&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Orbitron:wght@400..900&family=Poiret+One&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rowdies:wght@300;400;700&family=Sofia&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Tangerine:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.macondo-regular {
  font-family: "Macondo", cursive;
  font-weight: 400;
  font-style: normal;
}
.sofia-regular {
  font-family: "Sofia", cursive;
  font-weight: 400;
  font-style: normal;
}
.comic-neue-light {
  font-family: "Comic Neue", cursive;
  font-weight: 300;
  font-style: normal;
}

.comic-neue-regular {
  font-family: "Comic Neue", cursive;
  font-weight: 400;
  font-style: normal;
}

.comic-neue-bold {
  font-family: "Comic Neue", cursive;
  font-weight: 700;
  font-style: normal;
}

.comic-neue-light-italic {
  font-family: "Comic Neue", cursive;
  font-weight: 300;
  font-style: italic;
}

.comic-neue-regular-italic {
  font-family: "Comic Neue", cursive;
  font-weight: 400;
  font-style: italic;
}

.comic-neue-bold-italic {
  font-family: "Comic Neue", cursive;
  font-weight: 700;
  font-style: italic;
}
.poiret-one-regular {
  font-family: "Poiret One", sans-serif;
  font-weight: 800;
  font-style: bold;
}
.tangerine-regular {
  font-family: "Tangerine", cursive;
  font-weight: 400;
  font-style: normal;
}

.tangerine-bold {
  font-family: "Tangerine", cursive;
  font-weight: 700;
  font-style: normal;
}
.gruppo-regular {
  font-family: "Gruppo", sans-serif;
  font-weight: 600;
  font-style: normal;
}
/* .orbitron-<uniquifier> {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */
.montserrat-alternates-thin {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.montserrat-alternates-extralight {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.montserrat-alternates-light {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.montserrat-alternates-regular {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-alternates-medium {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.montserrat-alternates-semibold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.montserrat-alternates-bold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.montserrat-alternates-extrabold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.montserrat-alternates-black {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.montserrat-alternates-thin-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.montserrat-alternates-extralight-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.montserrat-alternates-light-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.montserrat-alternates-regular-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.montserrat-alternates-medium-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.montserrat-alternates-semibold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.montserrat-alternates-bold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.montserrat-alternates-extrabold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.montserrat-alternates-black-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.bree-serif-regular {
  font-family: "Bree Serif", serif;
  font-weight: 400;
  font-style: normal;
}
.indie-flower-regular {
  font-family: "Indie Flower", cursive;
  font-weight: 400;
  font-style: normal;
}
.rowdies-light {
  font-family: "Rowdies", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.rowdies-regular {
  font-family: "Rowdies", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.rowdies-bold {
  font-family: "Rowdies", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.cormorant-garamond-light {
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-style: normal;
}

.cormorant-garamond-regular {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  font-style: normal;
}

.cormorant-garamond-medium {
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  font-style: normal;
}

.cormorant-garamond-semibold {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  font-style: normal;
}

.cormorant-garamond-bold {
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-style: normal;
}

.cormorant-garamond-light-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-style: italic;
}

.cormorant-garamond-regular-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  font-style: italic;
}

.cormorant-garamond-medium-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  font-style: italic;
}

.cormorant-garamond-semibold-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  font-style: italic;
}

.cormorant-garamond-bold-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-style: italic;
}
.chakra-petch-light {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.chakra-petch-regular {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.chakra-petch-medium {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.chakra-petch-semibold {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.chakra-petch-bold {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.chakra-petch-light-italic {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.chakra-petch-regular-italic {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.chakra-petch-medium-italic {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.chakra-petch-semibold-italic {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.chakra-petch-bold-italic {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

